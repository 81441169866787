import React, { useState } from "react";
import LOGO from './img/LogoGreenpls.png'
import LINKEDIN from './img/LINKEDIN.png'

const Footer = () => {

    const [popupContent, setPopupContent] = useState('');
    const [isPopupVisible, setIsPopupVisible] = useState(false);

      // Función para cargar el contenido del pop-up
  const fetchPolicyContent = (policyType) => {
    const selectedLanguage = 'en'; // Cambia el idioma según sea necesario
    fetch(`/html/${policyType}_${selectedLanguage}.html`)
      .then((response) => response.text())
      .then((text) => {
        setPopupContent(text); // Establece el contenido del pop-up
        setIsPopupVisible(true); // Muestra el pop-up
      })
      .catch((error) => console.error('Error fetching policy:', error));
  };

      // Función para manejar el scroll al hacer clic
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container-footer">
          <div className="row-footer">
            {/* Sección de logo y contacto */}
            <div className="col-lg-3 col-md-6 footer-contact">
              <img
                src={LOGO}
                alt="Greenpls Logo"
                // style={{ width: "12%" }}
              />
              <p>
                Fernández de la Hoz 56 <br />
                Madrid, 28010<br />
                Spain <br />
                <br />
                <strong>Phone:</strong> +34 665 113 336<br />
                <strong>Email:</strong> contact@greenpls.com<br />
              </p>
            </div>

            {/* Sección de enlaces útiles */}
            <div class="footer-links">
    <h4>Useful Links</h4>
    <ul>
      <li><button
                className="nav-link-footer"
                onClick={() => scrollToSection('about')}
              >
                Inicio
              </button></li>
      <li><button
                className="nav-link-footer"
                onClick={() => scrollToSection('about')}
              >
                Plataforma
              </button></li>
      <li><button
                className="nav-link-footer"
                onClick={() => scrollToSection('about')}
              >
                Proyectos
              </button></li>
      <li><button
                className="nav-link-footer"
                onClick={() => scrollToSection('about')}
              >
                Preguntas Frecuentes
              </button></li>
      <li><button
                className="nav-link-footer"
                onClick={() => scrollToSection('about')}
              >
                Contacto
              </button></li>
    </ul>
  </div>
</div>
</div>
</div>

      {/* Sección de copyright y redes sociales */}
      <div className="container-footer-down">
        <div className="copyright-wrap d-md-flex py-4">
          <div className="me-md-auto text-left-footer text-md-start">
            <div className="copyright" style={{ fontSize: "10px" }}>
              &copy; Copyright{" "}
              <strong>
                <span style={{ fontSize: "12px" }}>Greenpls</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              <a
                href="https://bootstrapmade.com/"
                style={{ color: "rgba(40, 55, 40, 0.1)" }}
              >
                Designed with BootstrapMade
              </a>
            </div>
          </div>

          {/* Botones de políticas */}
          <div className="social-links text-right-footer text-md-right pt-3 pt-md-0">
          <button
            onClick={() => fetchPolicyContent('cookies_policy')}
            className="cookies-policy-button"
          >
            Cookies Policy
          </button>
          <button
            onClick={() => fetchPolicyContent('privacy_policy')}
            className="cookies-policy-button"
          >
            Privacy Policy
          </button>
          <button
            onClick={() => fetchPolicyContent('terms_policy')}
            className="cookies-policy-button"
          >
            Legal Advertisement & General Conditions
          </button>
            <a
              href="https://www.linkedin.com/company/greenpls"
              className="linkedin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LINKEDIN} alt="LinkedIn" className="linkedin-icon"/>
            </a>
          </div>
        </div>
                {/* Pop-up de políticas */}
                {isPopupVisible && (
          <div className="overlay-cookies" id="overlay-cookies">
            <div className="cookies-pop-up" id="cookiesPopUp">
              <button
                id="closeButton"
                onClick={() => setIsPopupVisible(false)} // Cierra el pop-up
              >
                X
              </button>
              <div
                id="cookiesContentWrapper"
                style={{ maxHeight: '300px', overflowY: 'auto' }}
              >
                <div id="cookiesPolicyTextContainer">
                  <div dangerouslySetInnerHTML={{ __html: popupContent }} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
 