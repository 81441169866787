import React, { useState } from "react";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Evitar recargar la página

    try {
      const response = await fetch("https://api.greenpls.com/api.php/sendmessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setFormData({ name: "", email: "", subject: "", message: "" });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your message has been sent. Thank you!"
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred. Please try again."
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again."
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="php-email-form">
      <div className="row-contact">
        <div className="col-md-6 form-group">
          <input
            type="text"
            name="name"
            className="form-control input-color"
            placeholder={t("faqname")}
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 form-group mt-3 mt-md-0">
          <input
            type="email"
            name="email"
            className="form-control input-color"
            placeholder={t("faqmail")}
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="row-contact-subject mt-3">
        <input
          type="text"
          name="subject"
          className="form-control input-color"
          placeholder={t("faqsubject")}
          value={formData.subject}
          onChange={handleChange}
          required
        />
      </div>
      <div className="row-contact-message mt-3">
        <textarea
          name="message"
          className="form-control input-color"
          rows="5"
          placeholder={t("faqmessage")}
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <div className="text-center-contact mt-3">
        <button type="submit">{t("contactsend")}</button>
      </div>
    </form>
  );
};

export default ContactForm;