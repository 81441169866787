import NavBar from "./NavBar";
import OffsetAutoGreen from './img/Auto-Offset-Green.svg';
import Cristalballs from './img/Cristalballs.mp4';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Logo from './img/polygon-matic-logo.png';
import LogoAntolin from './img/logo-antolin.png';
import LogoAlmar from './img/logo-almar.png';
import LogoByld from './img/logo-byld.png';
import Question2 from './img/icons8-question-mark-24.png';
import Earth from './img/earth0.png';
import ESG from './img/ESG.png';
import ContactForm from "./ContactForm";
import FAQSection from "./FAQSection";
import Footer from "./Footer";
import Mint from "./img/marketplaceMint.svg";
import Burn from "./img/OffsetWeb.svg";
import Offset from "./img/Auto-Offset.svg";
import Calculator from "./img/calculator3.svg";
import { FaArrowUp } from "react-icons/fa";

function Home() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    // Simula la carga inicial o espera a que se carguen los datos
    setTimeout(() => {
      setLoading(false); // Cambia a false después de la carga
    }, 2000); // Aquí se usa un timeout para simular la carga (ajusta el tiempo según tu app)
  }, []);

  // Mostrar el botón cuando el usuario hace scroll hacia abajo
  const checkScrollTop = () => {
    if (window.scrollY > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  // Manejar el evento de scroll
useEffect(() => {
window.addEventListener("scroll", checkScrollTop);
return () => {
  window.removeEventListener("scroll", checkScrollTop);
};
}, []);

// Función para volver arriba
const scrollToTop = () => {
window.scrollTo({ top: 0, behavior: "smooth" });
};
  
  const cards = [
      {
        title: t("servicescalcula"),
        subtitle: t("servicescalculatitle1"),
        text: t("servicescalculatext1"),
        subtitle2: t("servicescalculatitle2"),
        text2: t("servicescalculatext2"),
        imgSrc: Calculator, // Cambia la ruta de la imagen
      },
      {
        title: t("servicesmarketplace"),
        subtitle: t("servicesmarketplacetitle2"),
        text: t("servicesmarketplacetext2"),
        subtitle2: t("servicesmarketplacetitle3"),
        text2: t("servicesmarketplacetext3"),
        imgSrc: Mint, // Cambia la ruta de la imagen
      },
      {
        title: t("servicesoffset"),
        subtitle: t("servicesoffsettitle1"),
        text: t("servicesoffsettext1"),
        subtitle2: t("servicesoffsettitle3"),
        text2: t("servicesoffsettext3"),
        imgSrc: Burn, // Cambia la ruta de la imagen
      },
      {
        title: t("servicesearn"),
        subtitle: t("servicesearntitle1"),
        text: t("servicesearntext1"),
        subtitle2: t("servicesearntitle4"),
        text2: t("servicesearntext4"),
        imgSrc: Offset, // Cambia la ruta de la imagen
      },
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? cards.length - 1 : prevIndex - 1
      );
    };
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === cards.length - 1 ? 0 : prevIndex + 1
      );
    };

  return (
    <>
    {loading ? (
      <div id="preloader"></div>
    ) : (
    <div className="body-top ">
    <NavBar />
    
    <section id="home" className="d-flex align-items-center">
    <video src={Cristalballs} autoplay="true" muted="true" loop="true" playsInline="true"></video>
    {/* <div className="App-content"> */}
      <div className="home-text overlay " data-aos="fade-up">
        <div className="row content-center">
           <div className="text-center">
            <h1 >{t("hometitle")}</h1>
            <div className="typewriter">
              <h2  >{t("homesubtitle")}</h2>
            </div>
            <h2><a >{t("hometext1")}</a> <a className="fosforito" > {t("hometext2")} </a> <a >{t("hometext3")}</a> <a class="fosforito" > {t("hometext4")} </a> <a >{t("hometext5")}</a></h2>
            {/* <div><a href="https://app.greenpls.com" target="_blank" className="btn-get-started scrollto" {t()}"homelaunch">Launch app</a></div> */}
          </div>
        </div>
      </div>
      {/* </div> */}
  </section>
  <div className="App-content inicio">
  {/* <!-- ======= About Section ======= --> */}
  <section id="about" className="about ">
      {/* <!-- <div classNameName="section-title">
        <h2  {t()}"abouttitletop">NEXT GENERATION OF CARBON MARKETS</h2>
        <p  {t()}"aboutsubtitletop">Tokenization of markets and blockchain</p>
      </div> --> */}
      <div className="container ">
      <div className="row aboutsection"> 
        {/* <!-- First section: Trust in Carbon Markets --> */}
         {/*className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-1 content" data-aos="fade-right"> */}
         <div className="columns-about column-left-about">
          <h3 className="abouth3" >{t("abouttitle")}</h3>
          <p >{t("aboutsubtitle")} </p>
          {/* <!-- <ul>
            <li className="bi bi-check-circle" {t()}"abouttext1">  Ultimate transparency in origin, quality of carbon credits and destination of funds.</li>
            <li className="bi bi-check-circle" {t()}"abouttext2"> Complete trust without intermediaries.</li>
            <li className="bi bi-check-circle" {t()}"abouttext3"> Instant settlements.</li>
            <li className="bi bi-check-circle" {t()}"abouttext4"> Borderless access to capital for proyects.</li>
          </ul> --> */}
        </div>
        <div className="columns-about column-right-about">
          <div className="brillo"></div>
          <img style={{borderRadius: "1000px" }} src={Earth} className="about-earth-image" alt=""></img>
      </div>
      </div>
    </div>  
    {/* <!-- Second section: Driving Sustainability with Carbon Credits --> */}
    <div className="container ">
      <div className="row aboutsection"> 
        {/* <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-2 content" data-aos="fade-right"> */}
        <div className="columns-about column-left-about2">
          <div className="brillo2"></div>
          <img style={{borderRadius: "1000px" }} src={ESG} className="about-ESG-image" alt=""></img>
        </div>
        {/* <div className="col-lg-6 order-2 order-lg-1 image2" data-aos="zoom-in" data-aos-delay="150"> */}
        <div className="columns-about column-right-about2">
          <h3 className="abouth3" >{t("abouttitle2")}</h3>
            {/* <p >{t("aboutsubtitle2")}   </p> */}
            <p >{t("about2text1")} </p>
            {/* <!-- <p {t()}"about2text2">
              or
            </p>
            <p {t()}"about2text3">
              Certify your emissions offset if you wish.
            </p> --> */}
        </div>
      </div>
    </div>
    {/* { <!-- Third section: Blockchain Technology (Polygon) -->
    <!--  
    <div className="container ">
      <div className="row aboutsection"> 
        <div className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-1 content  d-flex flex-column justify-content-center" data-aos="fade-right">
          <h3 {t()}"abouttitle3">ECO-FRIENDLY BLOCKCHAIN TECHNOLOGY</h3>
          <p {t()}"aboutsubtitle3">
            By reducing intermediaries with blockchain, we minimize our carbon footprint. In addition, our platform is built on Polygon, a high-performance, scalable and environmentally friendly blockchain. This Layer 2 solution enables us to offer fast and cost-effective transactions for carbon credit management, further minimizing our environmental footprint.
          </p>
        </div>
        <div className="col-lg-6 order-2 order-lg-2 image" data-aos="zoom-in" data-aos-delay="150">
          <img src="assets/img/polygon-matic-logo.png" className="img-fluid" alt="Polygon">
        </div>
      </div>
    </div> --> */}
    </section> 
{/* <!-- End About Section -->  */}
    {/* ======= Counts Section ======= */}
  <section id="counts" className="counts">
    <div className="container">
        <div className="row-counts justify-content-center counts-text" data-aos="zoom-in">
            <h1  >{t("countstitle")}</h1>  
            {/* <h5><a {t()}"countstext1"> Click on the </a></h5> */}
        </div> 
    </div>
  </section>

<section id="clients" className="clients section">

{/* <div className="container" data-aos="fade-up"> */}

  <div className="row-clients gy-4">

    <div className="col-xl-2 col-md-3 col-6 client-logo">
      <img src={LogoAntolin} className="img-fluid" alt=""></img>
    </div>

    <div className="col-xl-2 col-md-3 col-6 client-logo">
      <img src={LogoAlmar} className="img-fluid" alt=""></img>
    </div>

    <div className="col-xl-2 col-md-3 col-6 client-logo">
      <img src={LogoByld} className="img-fluid" alt=""></img>
    </div>

  </div>
  </section>
  <section id="web3" className="web3">
  <div className="web3-section-title">
      <div className="brillo-web3"></div>
      <h2 className="web3title" >{t("web3title")}</h2>
      {/* <p className="web3subtitle" {t()}"web3subtitle">We explain step by step what you need to do to be able to get test carbon credits.</p>
      <p className="web3subtitle" {t()}"web3last">The final version will have more payment options without requiring self-custody wallets.</p> */}
    </div>
    <div className="carousel">
  {/* Contenedor principal */}
  <div className="carousel-container">
    {cards.map((card, index) => (
      <div
        className={`carousel-card ${
          index === currentIndex ? "active" : "hidden"
        }`}
        key={index}
      >
        <div className="card-content">
          <h2>{card.title}</h2>
          <h4>{card.subtitle}</h4>
          <p>{card.text}</p>
          <h4>{card.subtitle2}</h4>
          <p>{card.text2}</p>
        </div>
        <div className="card-content2">
          <img src={card.imgSrc} alt={card.title} className="card-image" />
        </div>
      </div>
    ))}
  </div>
{/* Indicadores */}
<div className="carousel-indicators">
    {cards.map((_, index) => (
      <span
        key={index}
        className={`indicator ${index === currentIndex ? "active" : ""}`}
        onClick={() => setCurrentIndex(index)}
      ></span>
    ))}
  </div>
  {/* Controles para flechas */}
  <div className="carousel-controls">
    <button className="carousel-btn prev" onClick={handlePrev}>
      &#8592;
    </button>
    <button className="carousel-btn next" onClick={handleNext}>
      &#8594;
    </button>
  </div>

  
</div>


    </section>
{/* </div> */}
{/* <!-- ======= Web3 Section ======= --> */}
<section id="web3" className="web3">
  <div className="container section-full web3container" data-aos="fade-up">
    <div className="web3-section-title">
      <div className="brillo-web3"></div>
      <h2 className="web3title" >{t("web3title")}</h2>
      <p className="web3subtitle" >{t("web3subtitle")}</p>
      <p className="web3subtitle" >{t("web3last")}</p>
    </div>
    <div className="row gy-4 web3grid">
      <div className="col-lg-4 col-md-6 d-flex align-items-stretch web3box" data-aos="zoom-in" data-aos-delay="100">
        <div className="icon-box iconbox-blue">
          <div className="icon">
            <svg width="85" height="85" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 15C20.5523 15 21 14.5523 21 14C21 13.4477 20.5523 13 20 13C19.4477 13 19 13.4477 19 14C19 14.5523 19.4477 15 20 15Z" fill="#0F0F0F"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.775 0.985398C18.4919 0.460783 20.2821 1.55148 20.6033 3.3178L20.9362 5.14896C22.1346 5.54225 23 6.67006 23 8V10.7639C23.6137 11.3132 24 12.1115 24 13V15C24 15.8885 23.6137 16.6868 23 17.2361V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V8C1 6.51309 2.08174 5.27884 3.50118 5.04128L16.775 0.985398ZM21 16C21.5523 16 22 15.5523 22 15V13C22 12.4477 21.5523 12 21 12H18C17.4477 12 17 12.4477 17 13V15C17 15.5523 17.4477 16 18 16H21ZM21 18V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V8C3 7.44772 3.44772 7 4 7H20C20.55 7 20.9962 7.44396 21 7.99303L21 10H18C16.3431 10 15 11.3431 15 13V15C15 16.6569 16.3431 18 18 18H21ZM18.6954 3.60705L18.9412 5H10L17.4232 2.82301C17.9965 2.65104 18.5914 3.01769 18.6954 3.60705Z" fill="#0F0F0F"/>
              </svg>
            {/* <!-- <i className="bx bxl-dribbble"></i> --> */}
          </div>
          <h4><a className="web3boxtitle1" >{t("web31title")}</a></h4>
          <a >{t("web31subtitle")}</a>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 d-flex align-items-stretch web3box" data-aos="zoom-in" data-aos-delay="200">
        <div className="icon-box iconbox-orange ">
          <div className="icon">
            {/* <!-- <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
              <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"></path>
            </svg> --> */}
            <svg width="85" height="85" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m4.929 4.93.001-.002.002.001.527-.528a.575.575 0 0 1 .786-.025l1.21 1.061c.332.305.774.492 1.26.492.514 0 .98-.21 1.316-.548.318-.32.52-.754.539-1.235h.004l.105-1.607a.575.575 0 0 1 .574-.537h.746V2v.002h.747c.303 0 .554.235.574.537l.105 1.607h.005c.019.484.223.92.544 1.24.336.335.8.543 1.312.543.492 0 .94-.192 1.272-.504l1.196-1.05a.575.575 0 0 1 .786.026l.528.528.002-.002v.002l-.001.002.528.527a.575.575 0 0 1 .026.786l-1.06 1.212a1.85 1.85 0 0 0-.492 1.258c0 .515.21.98.548 1.317.32.318.753.52 1.235.539v.004l1.606.105c.303.02.538.271.538.574V12H22v.002h-.002v.746a.575.575 0 0 1-.537.574l-1.607.107v.001c-.484.02-.92.223-1.24.544-.335.336-.543.8-.543 1.312 0 .486.187.928.493 1.26h-.002l1.062 1.211c.2.228.188.572-.026.786l-.528.528v.002h-.001l-.528.527a.575.575 0 0 1-.785.026l-1.168-1.021a1.851 1.851 0 0 0-1.302-.534c-.515 0-.98.21-1.317.548-.318.32-.52.755-.54 1.238h-.004l-.105 1.607a.575.575 0 0 1-.54.536H11.22a.575.575 0 0 1-.54-.536l-.105-1.607h-.004a1.851 1.851 0 0 0-.545-1.244 1.851 1.851 0 0 0-1.31-.542c-.504 0-.96.2-1.295.526l-1.177 1.03a.575.575 0 0 1-.785-.027l-.528-.528-.001-.001-.528-.528a.575.575 0 0 1-.026-.786l1.062-1.21-.001-.001a1.85 1.85 0 0 0 .493-1.26c0-.515-.21-.98-.548-1.317a1.85 1.85 0 0 0-1.236-.539v-.001l-1.607-.107a.575.575 0 0 1-.537-.574v-.746H2V12h.001v-.747c0-.303.235-.554.538-.574l1.606-.105v-.004a1.851 1.851 0 0 0 1.242-.545c.335-.336.542-.8.542-1.31 0-.49-.19-.935-.499-1.267L4.376 6.244a.575.575 0 0 1 .026-.786l.528-.527-.001-.002zM16.286 12a4.286 4.286 0 1 1-8.572 0 4.286 4.286 0 0 1 8.572 0z" fill="#000000"/></svg>
            {/* <!-- <i className="bx bx-file"></i> --> */}
          </div>
          <h4><a className="web3boxtitle2" >{t("web32title")}</a></h4>
          <a >{t("web32subtitle")}</a>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 d-flex align-items-stretch web3box" data-aos="zoom-in" data-aos-delay="200">
        <div className="icon-box iconbox-teal ">
          <div className="icon">
            {/* <!-- <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
              <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"></path>
            </svg> --> */}
            <svg width="85" height="85" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 6C16 8.20914 14.2091 10 12 10C9.79086 10 8 8.20914 8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6Z" fill="#1C274C"/>
              <path d="M15.6782 13.5028C15.2051 13.5085 14.7642 13.5258 14.3799 13.5774C13.737 13.6639 13.0334 13.8705 12.4519 14.4519C11.8705 15.0333 11.6639 15.737 11.5775 16.3799C11.4998 16.9576 11.4999 17.6635 11.5 18.414V18.586C11.4999 19.3365 11.4998 20.0424 11.5775 20.6201C11.6381 21.0712 11.7579 21.5522 12.0249 22C12.0166 22 12.0083 22 12 22C4 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C13.3262 13 14.577 13.1815 15.6782 13.5028Z" fill="#1C274C"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 22C14.8501 22 14.0251 22 13.5126 21.4874C13 20.9749 13 20.1499 13 18.5C13 16.8501 13 16.0251 13.5126 15.5126C14.0251 15 14.8501 15 16.5 15C18.1499 15 18.9749 15 19.4874 15.5126C20 16.0251 20 16.8501 20 18.5C20 20.1499 20 20.9749 19.4874 21.4874C18.9749 22 18.1499 22 16.5 22ZM18.468 17.7458C18.6958 17.518 18.6958 17.1487 18.468 16.9209C18.2402 16.693 17.8709 16.693 17.6431 16.9209L15.7222 18.8417L15.3569 18.4764C15.1291 18.2486 14.7598 18.2486 14.532 18.4764C14.3042 18.7042 14.3042 19.0736 14.532 19.3014L15.3097 20.0791C15.5375 20.307 15.9069 20.307 16.1347 20.0791L18.468 17.7458Z" fill="#1C274C"/>
              </svg>
              {/* <!-- <i className="bx bx-file"></i> --> */}
          </div>
          <h4><a className="web3boxtitle3" >{t("web34title")}</a></h4>
          <a >{t("web34subtitle")}</a>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 d-flex align-items-stretch web3box" data-aos="zoom-in" data-aos-delay="200">
        <div className="icon-box iconbox-pink ">
          <div className="icon">
            <svg width="85" height="85" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none">
              <g fill="#000000" fill-rule="evenodd" clip-rule="evenodd">              
              <path d="M5.791 3.318L3.316 5.793a1 1 0 000 1.414l2.475 2.475a1 1 0 001.415 0L9.68 7.207a1 1 0 000-1.414L7.206 3.318a1 1 0 00-1.415 0zm.707 4.95L4.731 6.5l1.767-1.768L8.266 6.5 6.498 8.268z"/>              
              <path d="M0 6.5a6.5 6.5 0 0112.346-2.845 6.5 6.5 0 11-8.691 8.691A6.5 6.5 0 010 6.5zm6.5-5a5 5 0 100 10 5 5 0 000-10zm6.5 5c0-.201-.01-.4-.027-.597a5 5 0 11-7.07 7.07A6.5 6.5 0 0013 6.5z"/>               
              </g>                
            </svg>
          </div>
          <h4><a className="web3boxtitle4" >{t("web33title")}</a></h4>
          <a >{t("web33subtitle")}</a>
        </div>
      </div>
      
    </div>
    
  </div>
</section>
{/* <!-- End Web3 Section -->  */}

  {/* ======= End Counts Section ======= */}
  {/* <section id="faq" className="faq">
  <div className="container section-full" data-aos="fade-up">
    <div className="section-title">
      <div className="brillo2"></div>
      <h2 {t()}"faqsection">FREQUENTLY ASKED QUESTIONS</h2>
    </div>
    <div className="faq-list">
      <ul>
        <li data-aos="fade-up" data-aos-delay="100">
          <div className='question-box'>
          <img className='question-icon' src={Question2} />
          <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1" {t()}"faqtitle1">What is a carbon credit? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>   
          </div>
          <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
            <p {t()}"faqtext1">Carbon credits are marketable permits that represent 1 tonne of CO2 that has been reduced or removed from the atmosphere. These permits allow the buying companies to emit a certain amount of CO2 or greenhouse gases.</p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay="200">
        <div className='question-box'>
        <img className='question-icon' src={Question2} />
          <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed" {t()}"faqtitle2">What are the carbon markets? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
          </div>
          <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
            <p {t()}"faqtext2">Carbon credits are traded in what is known as the carbon markets. There are two different carbon markets: 1) The compliance market. Where companies have the obligation to purchase a certain amount of carbon credits to comply with the emission level set by the authorities. 2) The voluntary market. Where companies purchase carbon credits beyond their obligations to meet more demanding goals like Net-Zero strategies.</p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay="300">
        <div className='question-box'>
        <img className='question-icon' src={Question2} />
          <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed" {t()}"faqtitle3">What are Net-Zero strategies? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
          </div>
          <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
            <p {t()}"faqtext3">These strategies aim to delete the environmental footprint of companies, people, or processes by reducing CO2 or greenhouse gas emissions. It is called "Net-Zero" because it is tough to reduce all emissions; purchasing permits is necessary to balance the residual emissions, and that is why carbon credits are so important.</p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="500">
        <div className='question-box'>
        <img className='question-icon' src={Question2} />
          <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed" {t()}"faqtitle5">What are the Sustainable Development Goals (SDGs)? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
          </div>
          <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
            <p {t()}"faqtext5">The Sustainable Development Goals (SDGs) are a set of 17 global goals adopted by all United Nations Member States in 2015 as part of the 2030 Agenda for Sustainable Development. They aim to address various global challenges, including poverty, hunger, health, education, gender equality, clean water, climate action, and more. These goals provide a shared blueprint for peace and prosperity for people and the planet.</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

{/* ======= Contact Section ======= *
<section id="contact" className="contact section-bg">
  <div className="container section-full contact-container" data-aos="fade-up">
    <div className="contact-section-title">
      <div className="brillo-contact"></div>
      <h2 {t()}"contact">Contact</h2>
      <p {t()}"contactsection">Get in touch with us!</p>
    </div>
    {/* Renderiza solo el componente ContactForm *
    <ContactForm />
  </div>
</section> */}
{/* <!-- End Contact Section --> */}
{/* <!-- FAQs Section --> */}
<FAQSection />
{/* ======= End FAQs Section ======= */}

{/* ======= Contact Section ======= */}
<section id="contact" className="contact section-bg">
  <div className="container section-full contact-container" data-aos="fade-up">
    <div className="contact-section-title">
      <div className="brillo-contact"></div>
      <h2 >{t("contact")}</h2>
      <p >{t("contactsection")}</p>
    </div>
    {/* Renderiza solo el componente ContactForm */}
    <ContactForm />
  </div>
</section>
{/* <!-- End Contact Section --> */}

{/* ======= Footer Section ======= */}
<Footer />
{/* <!-- End Footer Section --> */}

{/* <!-- Flecha Volver a Inicio --> */}
{showScroll && (
        <button
          className="scrollToTop"
          onClick={scrollToTop}
          aria-label="Scroll to top"
        >
          <FaArrowUp />
        </button>
      )}

</div>
 </div>
)}
    </>
  );
}

export default Home;