import React, { useState } from "react";
import QUESTION from './img/icons8-question-mark-24.png';
import { useTranslation } from 'react-i18next';

const FAQSection = () => {
  const { t } = useTranslation();
  const [openQuestion, setOpenQuestion] = useState(null);
  const toggleQuestion = (id) => {
    // Si la pregunta ya está abierta, ciérrala; si no, ábrela.
    setOpenQuestion(openQuestion === id ? null : id);
  };

  const faqs = [
    {
      id: 1,
      question: t("faqtitle1"),
      answer: t("faqtext1"),
    },
    {
      id: 2,
      question: t("faqtitle2"),
      answer: t("faqtext2"),
    },
    {
      id: 3,
      question: t("faqtitle3"),
      answer: t("faqtext3"),
    },
    {
      id: 4,
      question: t("faqtitle5"),
      answer: t("faqtext5"),
    },
  ];

  return (
    <section id="faq" className="faq">
      <div className="container-faq section-full" data-aos="fade-up">
        <div className="section-title-faqs">
          <div className="brillo-faqs"></div>
          <h2>{t("faqsection")}</h2>
        </div>
        <div className="faq-list-div">
        <ul className="faq-list">
  {faqs.map((faq) => (
    <li key={faq.id} className="faq-item">
      {/* Título de la pregunta */}
      <div
        className={`faq-header ${openQuestion === faq.id ? "active" : ""}`}
        onClick={() => toggleQuestion(faq.id)}
      >
        <img src={QUESTION} alt="question-icon" className="faq-icon" />
        <span className="faq-question">{faq.question}</span>
        <i
          className={`faq-toggle-icon ${
            openQuestion === faq.id ? "open" : ""
          }`}
        ></i>
      </div>

      {/* Contenido desplegable */}
      <div
        className={`faq-content ${openQuestion === faq.id ? "show" : ""}`}
        id={`faq-list-${faq.id}`}
      >
        <p>{faq.answer}</p>
      </div>
    </li>
  ))}
</ul>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
